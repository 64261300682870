<template>
<div class="create-tasks">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="create-tasks-nr">
    <div class="create-tasks-nr-l">
      <ChaptersList @change="changeChapter" :mapNodeIds="mapNodeIds || ''"></ChaptersList>
    </div>
    <div class="create-tasks-nr-r">
      <p class="tit">设置任务信息</p>
      <p class="sub-tit">任务名称</p>
      <Input v-model="name" placeholder="请输入任务名称" style="width: 400px" />

      <div class="bot-btn">
        <Button size="large" @click="cancel">取消</Button>
        <Button type="primary" size="large" @click="confirm">发布</Button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ChaptersList from '../trainingcamp/components/common/chaptersList';
import util from '@/utils/tools.js';
export default {
  name: "createTasks",
  data(){
    return{
      trainId:'',
      isEdit:false,
      name:'',
      content:'',
      courseId:'',
      groupList:[],
      groupIds:[],
      keyword:'',
      stuList:[],
      stuIds:[],
      curDistribution:'1',
      dateOptions:{
        disabledDate (date) {
          return date && date.valueOf() < Date.now() - 86400000;
        }
      },
      endTime:'',
      taskId:'',
      mapNodeIds:'',
    }
  },
  components:{
    ChaptersList
  },
  created(){
    this.courseId = this.$route.query.id || '';
    this.taskId = this.$route.query.taskId || '';
    if(this.taskId){
      this.isEdit = true;
      this.getDetail();
    }
    // this.getGroupList();
    // this.getUserList();
  },
  methods:{
    getDetail(type){
      // let params = {
      //   id:this.trainId
      // };
      // this.api.dataset.trainDataDetail(params).then((res)=>{
      //   this.info = res.info;
      //   this.categoryId = this.info.generate_category_id;
      //   this.curDistribution = this.info.attr.assign_type > 0 ? this.info.attr.assign_type : '1';
      //   if(this.curDistribution == '1'){
      //     this.groupIds = this.info.attr.assign_value ? this.info.attr.assign_value.split(',') : [];
      //   }else{
      //     this.stuIds = this.info.attr.assign_value ? this.info.attr.assign_value.split(',') : [];
      //   }
      //   this.endTime = util.timeFormatter(new Date(+this.info.end_time*1000), 'yyyy-MM-dd hh:mm')
      //   console.log(this.info.endTime,'this.info.endTime')
      // })
      let params = {
        task_id:this.taskId
      };
      this.api.course.courseTaskDetail(params).then((res)=>{
        this.mapNodeIds = res.task.map_node_ids;
        this.name = res.task.name;
      })
    },
    changeChapter(data){
      this.allChapterIds = data;
      // let params = {
      //   map_id:this.mapId,
      //   node_id:this.allChapterIds.join(',')
      // };

    },

    cancel(){
      this.$router.go(-1);
    },
    confirm(){
      if(this.loading) return;
      if(!this.allChapterIds.length){
        this.$Message.warning('请选择章节');
        return;
      }
      if(!this.name){
        this.$Message.warning('请输入名称');
        return;
      }
      // if(this.curDistribution == 1){
      //   console.log(this.groupIds,'this.groupIdsthis.groupIds')
      //   if(!this.groupIds.length){
      //     this.$Message.warning('请选择班级');
      //     return;
      //   }
      // }
      // if(this.curDistribution == 2){
      //   if(!this.stuIds.length){
      //     this.$Message.warning('请选择学生');
      //     return;
      //   }
      // }

      let params = {
        course_id:this.courseId,
        content:this.content,
        name:this.name,
        assign_type:this.curDistribution,
        map_node_ids:this.allChapterIds.join(','),
      }
      if(this.curDistribution == '1'){
        params.assign_value = this.groupIds.join(',');
      }else{
        params.assign_value = this.stuIds.join(',');
      }

      this.loading = true;
      if(this.taskId){
        params.task_id = this.taskId;
        this.api.course.courseTaskUpdate(params).then((res)=>{
          this.loading = false;

          this.$router.go(-1);
        }).catch((err)=>{
          this.loading = false;
        })
      }else{
        this.api.course.courseTaskCreate(params).then((res)=>{
          this.loading = false;

          this.$router.go(-1);
        }).catch((err)=>{
          this.loading = false;
        })
      }

    },
    // changeDate(data){
    //   this.info.endTime = data;
    // }
  }
}
</script>

<style scoped lang="scss">
.create-tasks{
  height: 100%;
  padding: 20px;
  .create-tasks-nr{
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    height: calc(100% - 40px);

    .create-tasks-nr-l{
      padding: 20px;
      flex: 1;
      width: 0;
      background-color: #FFFFFF;
    }
    .create-tasks-nr-r{
      margin-left: 20px;
      width: 600px;
      padding: 20px;
      background-color: #FFFFFF;
      height: 100%;
      overflow-y: auto;

      .tit{
        font-size: 16px;
        font-weight: bold;

      }
      .sub-tit{
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: bold;
      }
      .distribution{
        .distribution-tit{
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          >img{
            margin-right: 10px;
          }
        }
      }
      .bot-btn{
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
        >button{
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
